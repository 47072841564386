/* ==========
  Version 2.0
  Before / After Slider in Squarespace
  Copyright Will Myers
========== */
.wm-before-after-slider {
  --image-width: 500px;
  --aspect-ratio: 1 / 1;
  --border-thickness: 2px;
  --border-color: white;
  --shadow: none;
  
  --divider-thickness: 2px;
  --divider-color: white;
  
  --handle-size: 50px;
  --handle-thickness: 2px;
  --handle-color: black;
  --handle-border-color: white;
  --handle-background: hsla(0, 0%, 0%, 0);
  
  --caption-offset: 17px;
  --caption-font-size: .9rem;
  --caption-border-radius: 4px;
  --caption-padding-v: 8px;
  --caption-padding-h: 17px;
  --caption-after-background: #6338ff;
  --caption-after-color: white;
  --caption-before-background: #000000;
  --caption-before-color: white;
  --before-text: Before;
  --after-text: After;
  

  --shadow-light:
    0.4px 0.4px 5px hsla(0,0%,0%, 0.25),
    1.5px 1.4px 10px -3.9px hsla(0,0%,0%, 0.19);
  --shadow-medium:
    0.4px 0.4px 0.5px hsla(0,0%,0%, 0.33),
    9.6px 8.8px 11.8px -2px hsla(0,0%,0%, 0.24),
    25.5px 23.6px 31.5px -3px hsla(0,0%,0%, 0.19);
  --shadow-heavy: 
    0.4px 0.4px 0.5px hsla(0,0%,0%, 0.33),
    2.9px 2.7px 3.6px -1px hsla(0,0%,0%, 0.28),
    9.6px 8.8px 11.8px -2px hsla(0,0%,0%, 0.24),
    25.5px 23.6px 31.5px -3px hsla(0,0%,0%, 0.19),
    56px 51.7px 69.2px -3.9px hsla(0,0%,0%, 0.14);
}

.wm-before-after-slider {
  display: block;
  position: relative;
  box-sizing:border-box;
  border: var(--border-thickness) solid var(--border-color);
  box-shadow: var(--shadow);
  z-index:1;
}
.wm-before-after-slider *{
  box-sizing: border-box;
}
.wm-before-after-slider .overflow-wrapper {
  width: var(--image-width);
  position:relative;
  overflow: hidden;
}
.wm-before-after-slider img {
  display:block;
  width: var(--image-width);
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  cursor: col-resize;
  aspect-ratio: var(--aspect-ratio);
}
.wm-before-after-slider .after {
  cursor: col-resize;
  user-select:none;
}
.wm-before-after-slider .before-overflow {
  position: absolute;
  top: 0;  
  left: 0;
  width: calc(var(--slider-position) + (var(--divider-thickness) / 2));
  height: 100%;
  overflow: hidden;
  background: white;
  z-index: 1;
  object-fit:cover;
  user-select:none;
  cursor: col-resize;
}
.wm-before-after-slider .before-overflow img {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--image-width);;
  height: 100%;
  max-width: unset;
}

/*Captions*/
.wm-before-after-slider .before-title {
  opacity: calc(var(--slider-position));
}
.wm-before-after-slider .after-title {
  opacity: calc(100% - var(--slider-position));
}
.wm-before-after-slider .caption {
  position: absolute;
  top: 0;
  margin: var(--caption-offset) !important;
  display: inline-block;
  font-size:var(--caption-font-size);
  color: var(--caption-color);
  padding: var(--caption-padding-v) var(--caption-padding-h);
  border-radius: var(--caption-border-radius);
  z-index: 2;
  box-shadow: 0px 5px 9px hsla(0, 0%, 0%, .08);
  backdrop-filter: blur(5px);
  overflow:hidden;
}
.wm-before-after-slider .caption:after {
  content:'';
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index: -1;
}

.wm-before-after-slider .after-title {
  right: 0;
  color: var(--caption-after-color);
}
.wm-before-after-slider .caption.after-title:after{
  background-color: var(--caption-after-background);
  opacity: 70%;
}
.wm-before-after-slider .before-title {
  left: 0;
  color: var(--caption-before-color);
}
.wm-before-after-slider .caption.before-title:after{
  background-color: var(--caption-before-background);
  opacity: 70%
}

/*Divider & Handle*/
.wm-before-after-slider .divider {
  position: absolute;
  top:0;
  height: 100%;
  left: var(--slider-position);
  z-index:2;
  cursor: col-resize;
}
.wm-before-after-slider .line-top, 
.wm-before-after-slider .line-bottom{
  position:absolute;
  width: var(--divider-thickness);
  height: calc(50% - ((var(--handle-size) - var(--divider-thickness)) / 2));
  background: var(--divider-color);
}
.wm-before-after-slider .line-top{
  top: 0;
}
.wm-before-after-slider .line-bottom {
  bottom: 0;
}
.wm-before-after-slider .handle{
  display: block;
  position: absolute;
  box-sizing: border-box;
  background:var(--handle-background);
  top: 50%;
  left: calc(var(--slider-position) - ((var(--handle-size) - var(--divider-thickness)) / 2));
  transform: translateY(-50%);
  height: var(--handle-size);
  width: var(--handle-size);
  border: var(--divider-thickness) solid var(--handle-border-color);
  border-radius: 50%;
  padding:calc(var(--handle-size) / 5);
}
.handle path {
  stroke: var(--handle-color);
  stroke-width: calc( 2.5 * var(--handle-thickness));
}
body:not(.sqs-edit-mode-active) .hide-block {
  display:none;
}
